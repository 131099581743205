import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import uuid from 'uuid/v1'

import { warn } from 'utils'
import { rectifyTrade } from 'utils/trade'

export const COOKIE_NAME = 'magic-link-context'

// magic link cookie getter to hydrate customer tag from context
export const getCookie = key => {
    let cookie = document.cookie,
      output = cookie.split(/\s*;\s*/).reduce((ret, c) => {
        let pair = c.split(/\s*=\s*/)

        return {
          ...ret,
          [pair[0]]: pair
            .splice(1)
            .join('=')
            .replaceAll('+', '%20'), // TODO safe?
        }
      }, {})

    return decodeURIComponent(output[key])
  },
  convertMagicCookie = magicCookie => {
    let {
        firstName,
        lastName,
        phoneNumber,
        emailAddress,
        postalCode,
        zipCode,
        source,
        campaign,
        tradeParams = {},
        theme,
        rooftopName,
      } = magicCookie,
      magicCustomer

    // null the trade id on decode to force reselect of trim/options
    delete tradeParams.id
    delete tradeParams.tradePendingId

    magicCustomer = !isEmpty(magicCookie)
      ? {
          sessionId: magicCookie.deviceId || uuid(),
          firstName,
          lastName,
          source,
          campaign,
          phone: phoneNumber,
          email: get((emailAddress || '').split(';'), 0), // TODO sometimes there's a semicolon in the magic link
          zipCode: postalCode || zipCode, // this is to uuuu
          vehicleId: get(magicCookie, 'decodedInventoryParams.vehicleId'),
          trades: !isEmpty(tradeParams)
            ? [rectifyTrade(tradeParams, { confirmed: 0 })]
            : [],
          theme,
          rooftopName,
        }
      : {}

    return magicCustomer
  },
  getMagicCustomer = () => {
    let cookieData = {}

    try {
      cookieData = JSON.parse(getCookie(COOKIE_NAME))
    } catch (e) {
      warn('Failed to get magic customer')
    }
    return convertMagicCookie(cookieData)
  }

window.getMagicCustomer = getMagicCustomer
