import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import without from 'lodash/without'
import uuid from 'uuid/v1'

import { GET, POST } from 'actions/apiActions'
import { REPLACE_ENTITIES, MERGE_ENTITIES } from 'reducers/entityReducers'
import { getEntity } from 'selectors/entitySelectors'
import { warn } from 'utils'

import {
  LOADING_VEHICLES,
  LOADED_VEHICLES,
  LOADED_FILTERS,
  LOADED_ALL_FILTERS,
  INVENTORY_FILTER_NS as NS,
  SET_SELECTED_FILTERS,
} from 'reducers/inventoryReducers'

import {
  getAllPins,
  getPreviouslySelectedFilters,
  getActiveInventoryFilters,
} from 'selectors/inventorySelectors'

import {
  addToPageState,
  addAndReplacePageState,
  removeFromPageState,
  removeNSFromPageState,
  pushRooftopRoute,
  replacePageState,
} from 'actions/pageStateActions'

import {
  getActiveQuery,
  getQueryValue,
  NS_SEPARATOR,
  isQueryParamsDifferent,
} from 'selectors/pageStateSelectors'

import { getQuotesForVehicle, createQuote } from 'actions/quoteActions'

import {
  getQuoteValue,
  getGlobalQuoteFilters,
  getQuoteTrackerStats,
  getPreviousQuotesForVehicle,
  getQuoteId,
} from 'selectors/quoteSelectors'

import {
  track,
  QUOTE_VIEWED,
  OPENED_DIFFERENT_VEHICLE,
  VEHICLE_PINNED,
  VEHICLE_UNPINNED,
} from 'actions/naughtyActions'

import {
  getTaggedCustomerFromState,
  getHasMdrive,
} from 'selectors/appStatusSelectors'

import { refreshPrograms } from 'actions/programActions'
import { getCustomerZip } from 'selectors/naughtySelectors'

import { tagCustomer } from 'actions/appStatusActions'

export const SEARCH_NS = 'search'
const QUOTE_FILTER_NS = 'q'

const enforceListParams = obj =>
  Object.keys(obj).reduce(
    (ret, k) => ({
      ...ret,
      [k]: Array.isArray(obj[k]) ? obj[k] : [obj[k]],
    }),
    {},
  )

export const getVehicles = () => (dispatch, getState) => {
    let [myInit, getKnownState, exact] = getActiveInventoryFilters(getState()),
      isMdrive = getHasMdrive(getState()),
      request = exact ? isMdrive ? POST.vehiclesExactMdrive : POST.vehiclesExact
                      : isMdrive ? POST.vehiclesMdrive : POST.vehicles

    dispatch({ type: LOADING_VEHICLES })

    return dispatch(request(myInit, null, getKnownState))
      .then(r => {
        let entities = get(r, 'vehicles', []).map(v => ({
            ...v,
            id: v.uuid,
            sellingPrice: v.sellingPrice || 0,
          })),
          lastPull = new Date().getTime()

        dispatch({
          type: REPLACE_ENTITIES,
          payload: entities.reduce(
            (ret, e) => ({
              ...ret,
              [e.id]: {
                ...e,
                _entityType: 'vehicle',
                quote: {
                  lastPull,
                  ...e.quote,
                },
              },
            }),
            {},
          ),
        })
        dispatch({
          type: LOADED_VEHICLES,
          payload: {
            vehicleIds: entities.map(e => e.id),
            totalCount: r.vehicleCount,
          },
        })
        dispatch({
          type: LOADED_FILTERS,
          payload: get(r, 'filters', []),
        })

        if (isMdrive) {
          // mdrive feature returns all filters in this call
          dispatch({
            type: LOADED_ALL_FILTERS,
            payload: get(r, 'genericFilters', []),
          })
        }

        dispatch(storeSelectedFilters())
      })
      .catch(e => warn(e))
  },
  getVehiclesForVLP = () => (dispatch, getState) => {
    let shouldFetch = state =>
      isQueryParamsDifferent(state, 'inventory') ||
      isQueryParamsDifferent(state, 'quote')

    return shouldFetch(getState())
      ? dispatch(refreshPrograms())
      : dispatch({ type: 'NO_OP' })
  },
  getVehicleDetails = (vId, qId) => (dispatch, getState) => {
    let usingMdrive = getHasMdrive(getState()),
      op = !usingMdrive
        ? GET.vehicleDetails({}, { uuid: vId })
        : POST.getVehicleDetailsMDrive({ body: { uuid: vId } })

    return dispatch(op).then(r => {
      let e = getEntity(getState(), vId)

      // if the prepencil included fees and taxes, set the ui flags as such
      // TODO???
      //set(r, 'quote.programsHaveFees', get(r, 'quote.includeFees', false))
      //set(r, 'quote.programsHaveTaxes', get(r, 'quote.includeTaxes', false))

      //set(r, 'quote.isLoading', true)
      //set(r, 'quote.gettingPrograms', true)

      dispatch({
        type: MERGE_ENTITIES,
        payload: {
          [vId]: {
            ...r,
            rich: true,
            _entityType: 'vehicle',
            quoteId: e.quoteId || qId || uuid(),
          },
        },
      })
    })
  },
  initInventoryList = () => (dispatch, getState) => {
    dispatch({ type: LOADING_VEHICLES })

    let prevFilters = getPreviouslySelectedFilters(getState())

    if (!isEmpty(prevFilters)) dispatch(replacePageState(prevFilters))

    if (!getHasMdrive(getState())) dispatch(getInventoryFilters(true)) //deprecated after mdrive
    dispatch(refreshPrograms())
  },
  getInventoryFilters = allFilters => (dispatch, getState) => {
    let getFilters = state => ({
        ...getActiveQuery(getState(), NS),
        ...getGlobalQuoteFilters(state),
      }),
      filters = getFilters(getState()),
      myInit = { body: allFilters ? {} : enforceListParams(filters) },
      getKnownState = s => getFilters(s)

    dispatch(POST.vehicleFilters(myInit, null, getKnownState)).then(r =>
      dispatch({
        type: allFilters ? LOADED_ALL_FILTERS : LOADED_FILTERS,
        payload: get(r, 'filters', []),
      }),
    )
  },
  addAndStoreFilter = params => dispatch => {
    dispatch(addAndReplacePageState(params))
    dispatch(storeSelectedFilters())
  },
  rectifyZipCode = () => async (dispatch, getState) => {
    let queryZip = getQueryValue(getState(), 'q.zipCode'),
      tagZip = await getCustomerZip(getState())

    return dispatch(addToPageState({ 'q.zipCode': queryZip || tagZip }))
  },
  initPins = (pinIds = {}) => (dispatch, getState) => {
    if (getHasMdrive(getState())) return dispatch(initPinsMdrive(pinIds))

    let pins = Object.keys(pinIds)

    if (isEmpty(pins)) return

    dispatch(tagCustomer(null, null, true, { quotes: pinIds }))

    dispatch(
      GET.vehiclesMulti({
        queryStringParameters: { uuid: pins },
      }),
    )
      .then(vehicles => {
        dispatch({
          type: MERGE_ENTITIES,
          payload: vehicles.reduce(
            (ret, e) => ({
              ...ret,
              [e.uuid]: {
                ...e,
                _entityType: 'vehicle',
                id: e.uuid,
              },
            }),
            {},
          ),
        })
      })
      .catch(e => console.log(e))
  },
  initPinsMdrive = (pinIds = {}) => (dispatch, getState) => {
    if (isEmpty(pinIds)) return

    let vehicleIds = Object.keys(pinIds),
      [myInit, getKnownState] = getActiveInventoryFilters(getState(), {
        vehicleIds,
      })

    dispatch(tagCustomer(null, null, true, { quotes: pinIds }))

    dispatch(POST.vehiclesMultiMdrive(myInit, null, getKnownState))
      .then(vehicles => {
        dispatch({
          type: MERGE_ENTITIES,
          payload: vehicles.reduce(
            (ret, e) => ({
              ...ret,
              [e.uuid]: {
                ...e,
                _entityType: 'vehicle',
                id: e.uuid,
              },
            }),
            {},
          ),
        })
      })
      .catch(e => console.log(e))
  },
  initInventoryDetails = (vId, qId) => dispatch => {
    dispatch(getQuotesForVehicle(vId))
      .then(() => dispatch(getVehicleDetails(vId, qId)))
      .then(() => dispatch(rectifyQuoteId(vId)))
      .then(qId => dispatch(createQuote(vId, qId)))
  },
  togglePin = (vId, qId = uuid()) => (dispatch, getState) => {
    let pins = getAllPins(getState()),
      pinExists = Object.keys(pins).includes(vId)

    if (pinExists) delete pins[vId]
    else pins[vId] = qId

    let eventType = pinExists ? VEHICLE_UNPINNED : VEHICLE_PINNED

    dispatch(tagCustomer(vId, qId, null, { quotes: pins }))
    dispatch(track(eventType, { vehicleId: vId }, true))
  },
  toggleSingleFilter = ({ filter, value, selected }) => dispatch => {
    let action = selected ? removeFromPageState : addToPageState

    dispatch(removeNSFromPageState([SEARCH_NS]))
    dispatch(action({ [`${NS}${NS_SEPARATOR}${filter}`]: [value] }))
    dispatch(refreshPrograms())
  },
  clearSelectedFilters = () => dispatch => {
    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: {},
    })

    //dispatch(initInventoryList())
  },
  storeSelectedFilters = () => (dispatch, getState) => {
    const inventory = getActiveQuery(getState(), 'inventory'),
      q = getActiveQuery(getState(), 'q'),
      payload = { inventory, q }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: Object.keys(payload).reduce((ret, k) => {
        Object.keys(payload[k]).forEach(k2 => {
          ret[`${k}.${k2}`] = payload[k][k2]
        })
        return ret
      }, {}),
    })
  },
  toggleFilter = ({ filter, value }, namespace = NS) => (
    dispatch,
    getState,
  ) => {
    let val = value[0].toString(),
      filterName = `${namespace}${NS_SEPARATOR}${filter}`,
      currentVal = getQueryValue(getState(), filterName, [])

    currentVal = Array.isArray(currentVal) ? currentVal : [currentVal]

    let newVal = currentVal.includes(val)
      ? without(currentVal, val)
      : [...currentVal, val]

    dispatch(addToPageState({ [filterName]: newVal }))
    dispatch(storeSelectedFilters())
    dispatch(getInventoryFilters())
  },
  replaceFilter = ({ filter, value }, namespace = NS) => dispatch => {
    let filterName = `${namespace}${NS_SEPARATOR}${filter}`

    if (!value) dispatch(removeFromPageState(filterName))
    else dispatch(addToPageState({ [filterName]: value }))

    dispatch(storeSelectedFilters())
    dispatch(refreshPrograms())
  },
  toggleQuoteFilter = (key, value) => dispatch => {
    dispatch(addToPageState({ [`${QUOTE_FILTER_NS}.${key}`]: value }))
    dispatch(refreshPrograms())
  },
  clearFilters = () => dispatch => {
    dispatch(removeNSFromPageState([NS, SEARCH_NS]))

    dispatch(getInventoryFilters(true)) // deprecated
    dispatch(refreshPrograms())
  },
  exactMatchSearch = value => dispatch => {
    dispatch(clearSelectedFilters())

    dispatch(pushRooftopRoute('/:dealerId/inventory'))
    dispatch(removeNSFromPageState([NS]))

    value
      ? dispatch(
          replacePageState({ [`${SEARCH_NS}${NS_SEPARATOR}term`]: value }),
        )
      : dispatch(removeNSFromPageState([SEARCH_NS]))

    dispatch(refreshPrograms())
  },
  setSortBy = sortBy => dispatch => {
    dispatch(addToPageState({ 'inventory.sortBy': sortBy }))
    dispatch(refreshPrograms())
  },
  trackView = (vehicleId, quoteId) => (dispatch, getState) => {
    let taggedCustomer = getTaggedCustomerFromState(getState()),
      intent = getQuoteValue(getState(), vehicleId, quoteId, 'intent')

    if (!isEmpty(intent)) {
      return dispatch(
        track(QUOTE_VIEWED, {
          vehicleId,
          quoteId,
          ...getQuoteTrackerStats(getState(), vehicleId, quoteId),
        }),
      )
    } else {
      let { stockNumber, year, make, model, trim, id } = getEntity(
        getState(),
        vehicleId,
      )

      return dispatch(
        track(OPENED_DIFFERENT_VEHICLE, {
          ...getQuoteTrackerStats(getState(), vehicleId, quoteId),
          quoteId: taggedCustomer.quoteId,
          vehicleId: taggedCustomer.vehicleId,
          lookedAt: {
            id,
            stockNumber,
            year,
            make,
            model,
            trim,
          },
        }),
      )
    }
  },
trackVdpExtras = (eventName, quoteId, vehicleId) => (dispatch, getState) => {
  let { stockNumber, year, make, model, trim, id } = getEntity(
    getState(),
    vehicleId,
  )
  dispatch(
    track(eventName,
          {quoteId: quoteId,
           vehicleId: vehicleId,
           lookedAt: {
             id,
             stockNumber,
             year,
             make,
             model,
             trim,
           },
          },
         true)
  )
},
  setNewStartingQuoteId = (vId, qId) => (dispatch, getState) => {
    let quoteId = qId || uuid()

    dispatch({ type: 'SET_NEW_STARTING_QUOTE_ID', payload: quoteId })
    dispatch({
      type: MERGE_ENTITIES,
      payload: { [vId]: { ...getEntity(getState(), vId), quoteId } },
    })

    return quoteId
  },
  rectifyQuoteId = (vId, redirect) => (dispatch, getState) => {
    let vehicle = getEntity(getState(), vId),
      queryQuoteId = getQueryValue(getState(), 'quoteId'),
      pq = getPreviousQuotesForVehicle(getState(), vId),
      match = Object.values(pq).find(q => q.id === vehicle.quoteId),
      existingQuoteIdForVehicle = getQuoteId(getState(), vId),
      newStartingId = queryQuoteId
        ? queryQuoteId
        : dispatch(setNewStartingQuoteId()),
      quoteId =
        existingQuoteIdForVehicle || match || queryQuoteId || newStartingId

    if (redirect || !queryQuoteId) dispatch(addAndReplacePageState({ quoteId }))

    return quoteId
  },
  getEvoxImage = ({ make, model, year, trim }) => dispatch => {
    return dispatch(POST.evoxImage({ body: { make, model, year, trim } }))
  }
