import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { findAllEntities } from 'selectors/entitySelectors'
import { getQueryValue, getParamValue } from 'selectors/pageStateSelectors'
import { STATE_KEY as SK } from 'reducers/naughtyReducers'
import { FIELD_LABELS, convertKey } from 'components/VRP/naughtyBitLookups'

import {
  getRooftopId,
  getRooftopInfo,
  getCustomer,
  getRooftopDefaultCustomerZip,
  getIsKnownCustomerFromState,
} from 'selectors/appStatusSelectors'

import {
  getQuoteId,
  getQuoteValue,
  getBestPayment,
  getCurrentQuoteValue,
} from 'selectors/quoteSelectors'
import { shouldTrack } from 'selectors/authSelectors'

import { getIsLoggedIn, getIsDealerBrowser } from 'selectors/authSelectors'

import { getEntity } from 'selectors/entitySelectors'

import { REACTIONS, INTENTS_STATS_KEYS } from 'reducers/quoteReducers'
import { SALES_ROOFTOPS } from 'utils'

import { NaughtyDB, CUSTOMER_KEY, SQUEEZE_KEY } from 'utils/NaughtyDB'
import { getMagicCustomer } from 'utils/MagicCookie'

// regular redux selectors for pulling out events from app state
export const getEventsForQuote = (state, qId) => {
    return findAllEntities(
      state,
      e => e._entityType === 'event' && get(e, 'payload.quoteId') === qId,
    )
  },
  getEventsForVehicle = (state, vId) => {
    return findAllEntities(
      state,
      e => e._entityType === 'event' && get(e, 'payload.vehicleId') === vId,
    )
  },
  getRooftopKey = (state, key) =>
    getRooftopId(state) ? `${getRooftopId(state)}-${key}` : null,
  xgetTaggedCustomer = async (state, noDealer) => {
    let magicCustomer = getMagicCustomer(),
      immortalCustomer = await NaughtyDB.get(
        getRooftopKey(state, CUSTOMER_KEY),
      ),
      customer = {
        ...magicCustomer,
        ...immortalCustomer,
      }

    return noDealer && customer._entityType === 'dealer' ? null : customer
  },
  getTaggedCustomer = (...args) => getCustomer(...args),
  getSqueezeLimit = state =>
    get(
      getRooftopInfo(state),
      'config.quote.scanOptions.[0].squeezeLimit',
      false,
    ),
  getSqueeze = async state => {
    let squeeze = await NaughtyDB.get(getRooftopKey(state, SQUEEZE_KEY))

    return squeeze
  },
  getSqueezeFromState = state => {
    let squeeze = get(getCustomer(state), SQUEEZE_KEY, 0)

    return squeeze
  },
  getTooSqueezed = async state => {
    let squeezeCount = parseInt(get(await getSqueeze(state), 'count', 0), 10),
      isKnownCustomer = getIsKnownCustomerFromState(state),
      squeeze = shouldTrack(state),
      squeezeLimit = parseInt(getSqueezeLimit(state), 10)

    return (
      squeeze &&
      !isNaN(squeezeLimit) &&
      squeezeCount >= squeezeLimit &&
      !isKnownCustomer
    )
  },
  getSelectedDateRange = (state, def) =>
    getQueryValue(state, 'stats.dateRange', def || 'today'),
  getSelectedEventType = state => getQueryValue(state, 'stats.eventType', null),
  getTotalCreatedStats = ({ illuminationBreakdown, totalsByType }) => {
    let current = get(illuminationBreakdown, 'current.quote_created', 0)

    return {
      value: current,
      diff: current - get(illuminationBreakdown, 'previous.quote_created', 0),
      full: true,
      arrow: true,
      substats: [
        {
          values: [
            { label: 'Lease', value: get(totalsByType, 'created.lease', 0) },
            { label: 'Loan', value: get(totalsByType, 'created.loan', 0) },
            { label: 'Cash', value: get(totalsByType, 'created.cash', 0) },
          ],
        },
        {
          values: [
            { label: 'New', value: get(totalsByType, 'created.new', 0) },
            { label: 'Used', value: get(totalsByType, 'created.used', 0) },
          ],
        },
      ],
    }
  },
  getOpenedStats = ({ illuminationBreakdown }) => {
    let views = get(illuminationBreakdown, 'current.quote_viewed', 0)

    return {
      value: views,
      full: true,
      // filterKey: 'quote_viewed',
      diff: views - get(illuminationBreakdown, 'previous.quote_viewed', 0),
      substats: [
        {
          values: [
            {
              label: 'Mobile',
              value: get(illuminationBreakdown, 'current.mobile_opens', 0),
            },
            {
              label: 'Desktop',
              value: get(illuminationBreakdown, 'current.desktop_opens', 0),
            },
          ],
        },
      ],
    }
  },
  getIlluminationStats = ({
    illuminationBreakdown = [],
    topInteractions = [],
  }) => {
    let total = get(illuminationBreakdown, 'current.total_illuminations', 0)

    return {
      value: total,
      arrow: true,
      full: true,
      diff:
        total - get(illuminationBreakdown, 'previous.total_illuminations', 0),
      substats: [
        {
          //vertical: true,
          limit: 3,
          values: topInteractions.map(({ key, count }) => ({
            label: FIELD_LABELS[key] || convertKey(key, true) || key,
            value: count,
          })),
        },
      ],
    }
  },
  getAcceptedStats = ({ illuminationBreakdown, totalsByType }) => {
    let accepted = get(illuminationBreakdown, 'current.quote_accepted', 0)

    return {
      value: accepted,
      double: true,
      arrow: true,
      // filterKey: 'quote_accepted',
      diff: accepted - get(illuminationBreakdown, 'previous.quote_accepted', 0),
      substats: [
        {
          values: [
            { label: 'Lease', value: get(totalsByType, 'accepted.lease', 0) },
            { label: 'Loan', value: get(totalsByType, 'accepted.loan', 0) },
            { label: 'Cash', value: get(totalsByType, 'accepted.cash', 0) },
          ],
        },
        {
          values: [
            { label: 'New', value: get(totalsByType, 'accepted.new', 0) },
            { label: 'Used', value: get(totalsByType, 'accepted.used', 0) },
          ],
        },
      ],
    }
  },
  getEngagedStats = ({ illuminationBreakdown }) => {
    let engaged = get(illuminationBreakdown, 'current.engagements', 0)

    return {
      value: engaged,
      // filterKey: 'current.engagements',
      diff: engaged - get(illuminationBreakdown, 'previous.engagements', 0),
      arrow: true,
    }
  },
  getTotalReactions = ({ illuminationBreakdown }) => {
    let totals = get(illuminationBreakdown, 'current.total_reactions', 0)

    return {
      value: totals,
      // filterKey: 'current.total_reactions',
      diff: totals - get(illuminationBreakdown, 'previous.total_reactions', 0),
      arrow: true,
    }
  },
  getCounteredStats = ({ illuminationBreakdown }) => {
    let rejected = get(illuminationBreakdown, 'current.quote_rejected', 0)

    return {
      value: rejected,
      // filterKey: 'quote_rejected',
      diff: rejected - get(illuminationBreakdown, 'previous.quote_rejected', 0),
      arrow: true,
    }
  },
  getHotLeads = ({ illuminationBreakdown }) => {
    let hotLeads = get(illuminationBreakdown, 'current.known_customers', 0)

    return {
      value: hotLeads,
      // filterKey: 'quote_rejected',
      diff:
        hotLeads - get(illuminationBreakdown, 'previous.known_customers', 0),
      arrow: true,
    }
  },
  getHotLeadAlerts = ({ illuminationBreakdown }) => {
    let hotLeadAlerts = get(illuminationBreakdown, 'current.adfs_sent', 0)

    return {
      value: hotLeadAlerts,
      // filterKey: 'quote_rejected',
      diff: hotLeadAlerts - get(illuminationBreakdown, 'previous.adfs_sent', 0),
      arrow: true,
    }
  },
  getServiceIqEmailsSent = ({ serviceiqBreakdown }) => {
    if (serviceiqBreakdown) {
      let totals = get(serviceiqBreakdown, 'current.emails_sent', 0)

      return {
        value: totals,
        diff: totals - get(serviceiqBreakdown, 'previous.emails_sent', 0),
        arrow: true,
        makeOnClick: showServiceIqDialog => {
          return () => {
            showServiceIqDialog()
          }
        },
      }
    } else {
      return {
        hidden: true,
      }
    }
  },
  getRawAggregateValues = state => get(state, `${SK}.aggregateStats`, []),
  getAssembledAggregates = state => {
    let raw = getRawAggregateValues(state)
    return [
      // {
      //   Opened: getOpenedStats(raw),
      // },
      // {
      //   Created: getTotalCreatedStats(raw),
      // },
      {
        Engaged: getEngagedStats(raw),
      },
      {
        Activity: getIlluminationStats(raw),
      },
      {
        Reactions: getTotalReactions(raw),
      },
      {
        'Hot Leads': getHotLeads(raw),
      },
      {
        'Hot Lead Alerts': getHotLeadAlerts(raw),
      },
      // {
      //   'ServiceIQ Emails Sent': getServiceIqEmailsSent(raw),
      // },
    ]
  },
  getReactionStatsOld = state => {
    let tops = get(getRawAggregateValues(state), 'topInteractions', []),
      reactions = REACTIONS.reduce(
        (ret, k) => ({
          ...ret,
          [k]: get(tops.find(t => t.key === k), 'count', 0),
        }),
        {},
      )

    return reactions || {}
  },
  getReactionStats = state => {
    let currentIllums = get(
      getRawAggregateValues(state),
      'illuminationBreakdown.current',
      [],
    )

    return REACTIONS.reduce(
      (ret, rk) => ({ ...ret, [rk]: currentIllums[INTENTS_STATS_KEYS[rk]] }),
      {},
    )
  },
  getIsSalesRooftop = state => SALES_ROOFTOPS.includes(getRooftopId(state)),
  getSalesSqueeze = state => {
    let isSalesRooftop = getIsSalesRooftop(state),
      existingCustomer = getCustomer(state),
      { firstName } = existingCustomer

    return shouldTrack(state) && isSalesRooftop && isEmpty(firstName)
  },
  getIlluminationsForStats = state => get(state, `${SK}.illuminations`, []),
  getMapStats = state => get(state, `${SK}.mapStats`, []) || [],
  isGettingStats = state => get(state, `${SK}.isGettingStats`, false),
  getGTMPayload = state => {
    let customer = getCustomer(state),
      vehicleId = getParamValue(state, 'id'),
      quoteId = getQuoteId(state, vehicleId),
      reaction = getQueryValue(state, 'quoteAction'),
      hasTrades = get(customer, 'trades', []).some(t => t.allowance > 0),
      vehicle = getEntity(state, vehicleId),
      gqv = key => getQuoteValue(state, vehicleId, quoteId, key),
      mode = gqv('mode'),
      payment = getBestPayment(state, vehicleId, quoteId, mode),
      zipcode = gqv('customerZipCode')

    return !isEmpty(quoteId) && !isEmpty(zipcode)
      ? {
          VIN: vehicle.vin,
          Year: vehicle.year,
          Make: vehicle.make,
          Model: vehicle.model,
          Condition: vehicle.type,
          TransactionType: mode,
          PaymentAmount: payment,
          QuoteInterest: reaction,
          ZipCode: zipcode,
          HasTrades: hasTrades,
          MagicLinked: !!customer.isMagicCustomer,
          LeadSubmitted: !!customer.savedAnyQuote,
        }
      : {}
  },
  isUserWet = state => {
    let customer = getCustomer(state),
      { _entityType } = customer

    return !!_entityType
  },
  isKnownCustomer = state => {
    let customer = getCustomer(state)

    return (
      getIsDealerBrowser(state) ||
      !isEmpty(getIsLoggedIn(state)) ||
      !isEmpty(get(customer, 'email', '')) ||
      !isEmpty(get(customer, 'phone', ''))
    )
  },
  getImmortalPins = state => {
    let customer = getCustomer(state)

    return get(customer, 'quotes', {})
  },
  getCustomerHasNoRebates = state => {
    let customer = getCustomer(state)

    return get(customer, 'hasNoRebates', false)
  },
  getCustomerName = state => {
    let customer = getCustomer(state),
      firstName = get(customer, 'firstName'),
      lastName = get(customer, 'lastName')

    return [firstName, lastName]
  },
  getCustomerTrades = state => {
    let customer = getCustomer(state)

    return get(customer, 'trades', [])
  },
  getCustomerSellTrade = state => {
    let customer = getCustomer(state)

    return get(customer, 'sellMyTrade', false)
  },
  getCustomerHasNoTrade = state => {
    let customer = getCustomer(state)

    return get(customer, 'hasNoTrade', false)
  },
  getCustomerZip = state => {
    let zip = get(getCustomer(state), 'zipCode'),
      locationZip = get(getCustomer(state), 'location.zipCode'),
      queryZip = getQueryValue(state, 'q.zipCode'),
      rooftopZip = getRooftopDefaultCustomerZip(state),
      quoteZip = getCurrentQuoteValue(state, 'customerZipCode')

    return quoteZip || locationZip || queryZip || zip || rooftopZip
  },
  getCustomerTradeEquity = state => {
    let customer = getCustomer(state)

    return get(customer, 'trades', []).reduce(
      (ret, t) => ret + get(t, 'allowance', 0) - get(t, 'payoff', 0),
      0,
    )
  }
