import get from 'lodash/get'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import uniq from 'lodash/uniq'
import memoize from 'lodash/memoize'
import moment from 'moment'
import mean from 'lodash/mean'
import isNull from 'lodash/isNull'
import sortBy from 'lodash/sortBy'

import {
  getRooftopId,
  getRooftopConfigGroup,
  getCustomer,
  getIsKnownCustomerFromState,
  getPaymentMods,
  getHidePriceToUnknownSetting,
  getPaymentSearchWiggle,
  getHasMdrive,
  isSqueezeJacketOpen,
  getHasUseFtcCompliantDisplay,
} from 'selectors/appStatusSelectors'

import { getIsLoggedIn } from 'selectors/authSelectors'

import { getEntity, findAllEntities } from 'selectors/entitySelectors'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import {
  getActiveQuery,
  getQueryValue,
  getParamValue,
  getActiveHash,
} from 'selectors/pageStateSelectors'
import { INTENTS, STATE_KEY } from 'reducers/quoteReducers'
import {
  getIsUsedCar,
  getWarrantyInfo,
  getDataOneWarranties,
} from 'selectors/inventorySelectors'

import {
  money,
  moneySuperShort,
  roundPayment,
  moneyShort,
  numShort,
  values,
  keys,
  info,
} from 'utils'

import { STATUS as S } from 'reducers/quoteReducers'

import React from 'react'
import c from 'classnames'
import Icon from 'discada/dist/Icon'

import { getWritePermission } from 'selectors/authSelectors'

import {
  getShouldIncludeFeesSetting,
  getShouldIncludeTaxesSetting,
} from 'selectors/appStatusSelectors'

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

const MAP_FOR_DEFAULTS = {
  loanTerms: 'loanTerm',
  leaseTerms: 'leaseTerm',
  creditTiers: 'creditScore',
  downs: 'down',
  mileages: 'miles',
}

const FORMATTERS = {
  loanTerms: x => x,
  leaseTerms: x => x,
  creditTiers: x => x,
  downs: x => x,
  mileages: x => x,
}

const KEY_INDICES = {
  mode: 0,
  term: 1,
  loanTerm: 1,
  leaseTerm: 1,
  down: 2,
  creditScore: 3,
  miles: 4,
}

const GLOBAL_FILTERS = {
  loanTerm: true,
  leaseTerm: true,
  down: false,
  creditScore: false,
  miles: false,
}

export const CASH_PROGRAM_PATH = 'cash|0|0|0|0'
export const WILDCARD = 'any'

export const g = (pd, key, defaultValue) => get(pd, key, defaultValue),
  s = (pd, key, valueKey = 'value', f = v => v) =>
    g(pd, key, [])
      .filter(f)
      .reduce((ret, v) => ret + get(v, valueKey, 0), 0)

export const getQuoteConfig = (state, options = {}) => {
    let config = getRooftopConfigGroup(state, 'quote', {}),
      hasMdrive = getHasMdrive(state),
      { grayList = [] } = options

    grayList = hasMdrive ? grayList : [] // grayList only works for mDrive

    let defaults = keys(config).reduce(
      (ret, k) =>
        MAP_FOR_DEFAULTS[k] &&
        !grayList.includes(k) &&
        !grayList.includes(MAP_FOR_DEFAULTS[k])
          ? {
              ...ret,
              [MAP_FOR_DEFAULTS[k]]: get(
                config[k].find(c => c.default),
                'value',
                0,
              ),
            }
          : ret,
      {},
    )

    return { ...config, ...defaults }
  },
  getQuoteId = (state, vId) => {
    let { quotes } = getCustomer(state),
      queryId = getQueryValue(state, 'quoteId'),
      startingQuoteId = getStartingQuoteIdForVehicle(state, vId)

    vId = vId || getParamValue(state, 'id')

    let ret = get(quotes, vId, queryId || startingQuoteId)

    return ret
  },
  getInitialQuote = state => {
    let init = get(state, STATE_KEY)

    delete init.rebateInfo

    return {
      ...init,
      //...getGlobalQuoteFilters(state),
    }
  },
  doesQuoteExist = (state, qId) => {
    return !isEmpty(getEntity(state, qId))
  },
  getUrlQuoteParams = (state, filterWildCard = true) => {
    // non-Array version of getUrlQuote
    let urlQuote = getUrlQuote(state, filterWildCard), // url params in q namespace, from vlp or otherwise
      urlParams = keys(urlQuote).reduce(
        (ret, k) => ({ ...ret, [k]: get(urlQuote[k], 0) }),
        {},
      ) // url params in q namespace, from vlp or otherwise, flattened

    return urlParams
  },
  getUrlQuote = (state, filterWildCard) => {
    let urlQuote = getActiveQuery(state, 'q')

    return Object.keys(urlQuote).reduce(
      (ret, k) =>
        isNull(urlQuote[k]) || (filterWildCard && urlQuote[k] === WILDCARD)
          ? ret
          : {
              ...ret,
              [k]: Array.isArray(urlQuote[k])
                ? urlQuote[k]
                : get(urlQuote, k, '')
                    .toString()
                    .split('-'),
            },
      {},
    )
  },
  //gets the selectable options for a quote based on rooftop config and url
  //params,doesn't take persisted quotes into account
  getSelectableQuoteGlobals = (state, insertNonValidOptions) => {
    let config = getQuoteConfig(state),
      urlQuote = getUrlQuoteParams(state),
      globs = {
        ...config,
        ...urlQuote,
      },
      defLabel = v => v, // default label for when ephemeral value exists
      coordKeys = {
        leaseTerms: { key: 'leaseTerm' },
        loanTerms: { key: 'loanTerm' },
        downs: { key: 'down', label: v => moneyShort(v) },
        creditTiers: { key: 'creditScore' },
        mileages: { key: 'miles', label: v => numShort(v) },
      },
      coords = Object.keys(coordKeys).reduce((ret, k) => {
        let options = get(globs, k, []),
          coord = coordKeys[k],
          value = get(globs, coord.key),
          label = (coord.label || defLabel)(value)

        if (
          value &&
          insertNonValidOptions &&
          !options.map(o => o.value.toString()).includes(value.toString())
        ) {
          options = [
            ...options,
            { value: parseInt(value, 10), label, default: false },
          ]
        }

        return { ...ret, [k]: sortBy(options, o => o.value) }
      }, {})

    return coords
  },
  getGlobalQuoteFilters = createSelector(
    [
      s => getUrlQuoteParams(s),
      s => getPaymentSearchWiggle(s),
      (s, options = {}) => getQuoteConfig(s, options),
      (s, options = {}) =>
        options.includeSelectable ? getSelectableQuoteGlobals(s) : {},
      (_, options) => options,
      s => getCustomer(s),
    ],
    (
      urlParams,
      wiggle,
      config = {},
      selectableGlobals = {},
      options = {},
      taggedCustomer,
    ) => {
      // combines the default pencil config with params coming in via the url to
      // generate the set of selected and selectable options for a quote.
      let { trimmed } = options,
        payment = parseInt(get(urlParams, 'payment', 0), 10), // get the payment from the url if set
        paymentMod = payment * wiggle,
        ret = {
          ...config,
          ...urlParams,
        },
        whiteList = keys(GLOBAL_FILTERS)

      // enforce vals if missing from ret
      keys(GLOBAL_FILTERS).forEach(k => {
        if (!GLOBAL_FILTERS[k]) ret[k] = ret[k] || config[k]
      })

      // process ret vals based on options
      ret = keys(ret).reduce(
        (r, k) =>
          !trimmed || whiteList.includes(k)
            ? { ...r, [k]: trimmed ? parseInt(ret[k], 10) : ret[k] }
            : r,
        {},
      )

      // inject missing values into selectable options
      options.injectMissingValues &&
        Object.keys(MAP_FOR_DEFAULTS).forEach(k => {
          let options = (ret[k] || []).map(r => r.value),
            selectedValue = ret[MAP_FOR_DEFAULTS[k]]

          if (!options.find(o => o.toString() === selectedValue.toString())) {
            ret[k] = [
              ...(ret[k] || []),
              {
                value: selectedValue,
                label: FORMATTERS[k](selectedValue),
                default: false,
              },
            ]
          }
        })

      if (payment) {
        ret = {
          ...ret,
          minPayment: parseInt((payment - paymentMod).toString(), 10),
          maxPayment: parseInt((payment + paymentMod).toString(), 10),
        }
      }

      ret = { ...ret, ...selectableGlobals }

      // get creditScore from tag
      ret.creditScore = get(taggedCustomer, 'creditScore', ret.creditScore || 0)

      ret.allowance = parseInt(
        get(taggedCustomer, 'trades', []).reduce(
          (ret, t) => ret + get(t, 'allowance', 0),
          0,
        ),
        10,
      )

      ret.payoff = parseInt(
        get(taggedCustomer, 'trades', []).reduce(
          (ret, t) => ret + get(t, 'payoff', 0),
          0,
        ),
        10,
      )

      return ret
    },
  ),
  getQuoteFromVehicle = (state, vId) => {
    let prepencil = get(getEntity(state, vId), 'quote', {})

    return prepencil
  },
  getQuoteParts = createSelector(
    [
      (_, vId, qId, mode, extra) => ({ vId, qId, mode, extra }),
      getInitialQuote,
      s =>
        getGlobalQuoteFilters(s, {
          includeSelectable: true,
          includeDefaults: true,
        }),
      getQuoteFromVehicle,
      (s, _, qId) => getEntity(s, qId),
      s => getActiveHash(s),
    ],
    ({ vId, qId, mode, extra }, init, global, preQuote, quote, activeHash) => {
      let q = {
        id: qId,
        vehicleId: vId, // add the vehicleId just so we have it
        mode: mode || activeHash,
        ...init, // appState quote to apply global values
        ...preQuote, // get a pre-pencil quote and smash it in
        ...global, // vlp and query filter
        ...quote, // merge in a persisted or app state quote, if there is one
        ...extra, // allow for extra or override data to be put into the quote
      }

      q.term = q[`${q.mode}Term`] // for easy display

      return q
    },
  ),
  getQuote = createSelector(
    [
      (state, vId, qId, mode, extra) => {
        let { vehicle, quote } = getQuoteIds(state)

        return getQuoteParts(state, vId || vehicle, qId || quote, mode, extra)
      },
      s => getCustomer(s),
    ],
    (q, taggedCustomer = {}) => {
      if (
        !isEmpty(taggedCustomer) &&
        taggedCustomer._entityType === 'customer'
      ) {
        // override the quote customer with any tagged info
        q.customer = {
          ...q.customer,
          ...taggedCustomer,
        }

        // replace  zip and taxRegionId with tagged data
        q.customerZipCode =
          get(taggedCustomer, 'location.zipCode') ||
          get(taggedCustomer, 'zipCode') ||
          q.customerZipCode

        q.taxRegionId =
          get(taggedCustomer, 'location.taxRegionId') ||
          get(taggedCustomer, 'taxRegionId') ||
          q.taxRegionId

        q.stateFeeTax =
          get(taggedCustomer, 'location.stateFeeTax') ||
          get(taggedCustomer, 'stateFeeTax') ||
          q.taxRegionId

        // replace rebates with tagged data
        q.selectedRebateCategories =
          [...get(taggedCustomer, 'selectedRebateCategories', [])] ||
          q.selectedRebateCategories

        // replace trades with tagged data
        q.trades = [...get(taggedCustomer, 'trades', [])] || q.trades

        // replace creditScore with tagged data
        if (taggedCustomer.creditScore >= 0)
          q.creditScore = taggedCustomer.creditScore || q.creditScore
      }

      return q
    },
  ),
  getVehicleLink = (state, vId) => {
    let rooftopId = getRooftopId(state)

    return `${window.location.origin}/${rooftopId}/vehicle/${vId}`
  },
  getQuoteShortLink = (state, vId, qId) => {
    let shortCode = getQuoteValue(state, vId, qId, 'shortCode'),
      rooftopId = getRooftopId(state)

    if (!shortCode) return null

    return `${window.location.origin}/${rooftopId}/q/${shortCode}`
  },
  getInsurancePartnerLink = (state, vId, qId) => {
    return getQuoteValue(state, vId, qId, 'insurancePartnerLink')
  },
  getBlinderShortLink = (state, vId, qId) => {
    let shortCode = getQuoteValue(state, vId, qId, 'shortCode'),
      rooftopId = getRooftopId(state)

    if (!shortCode) return null

    return `${window.location.origin}/${rooftopId}/b/${shortCode}`
  },
  getQuoteStatus = (state, vId, qId) =>
    getQuoteValue(state, vId, qId, 'status'),
  isQuoteSaving = (state, vId, qId) =>
    getQuoteStatus(state, vId, qId) === S.saving,
  getQuoteInstance = () => getQuote,
  getQuoteForProgramPull = (state, vId, qId) => {
    let quote = getQuote(state, vId, qId),
      hasMdrive = getHasMdrive(state)

    let neededKeys = [
        'id',
        'customerZipCode',
        'daysToFirstPayment',
        'creditTiers',
        'desiredPayment',
        'discounts',
        'down',
        'downs',
        'downPaymentUsage',
        'dealerIncentivesReducePrice',
        'paidReserveReducesPrice',

        'fees', // TODO mdrive?
        'leaseTerms',
        'loanTerms',
        'mileages',
        'rebateInfo',

        'vehicleId',
        'price',
        'msrp',
        'adjMsrp',
        'invoice',
        'cost',
        'odometer',

        'additions', // TODO mdrive?
        'trades',
        'warranties', // TODO mdrive?
        'maintenancePlans', // TODO mdrive?
        'vehicleOptionCode',

        'rateMarket',
        'includeTaxesAndFees',
        'includeTaxes',
        'includeFees',
        'programsHaveTaxesAndFees',
        'programsHaveTaxes',
        'programsHaveFees',

        'taxRegionId',
        'stateFeeTax',
        'override',
      ],
      nonMdriveKeys = [
        'desiredPaidReserve',
        'desiredPaidReserveRange',
        'aprMarkup',
        'moneyFactorMarkup',
        'profitStyle',
        'acceptLowerMaxRate',
      ],
      mDriveKeys = [
        'leaseDesiredPaidReserveRange',
        'leaseDesiredPaidReserve',
        'leaseAprMarkup',
        'leaseMoneyFactorMarkup',
        'leaseAcceptLowerMaxRate',
        'leaseProfitStyle',
        'loanDesiredPaidReserveRange',
        'loanDesiredPaidReserve',
        'loanAprMarkup',
        'loanAcceptLowerMaxRate',
        'loanProfitStyle',
        'daysToFirstPayment',
        'includeFirstPaymentWaived',
      ]

    neededKeys = [...neededKeys, ...(hasMdrive ? mDriveKeys : nonMdriveKeys)]

    let ret = neededKeys.reduce((acc, k) => {
      acc[k] = quote[k]
      return acc
    }, {})

    ret.includeTaxes = ret.includeTaxes || shouldQuoteHaveTaxes(state, vId, qId)
    ret.includeFees = ret.includeFees || shouldQuoteHaveFees(state, vId, qId)

    return ret
  },
  getQuoteIds = (state, vId, qId) => {
    return {
      vehicle: vId || getParamValue(state, 'id'),
      quote: qId || getQueryValue(state, 'quoteId'),
    }
  },
  getCurrentQuoteValue = (state, key, defaultValue) => {
    let { vehicleId, quoteId } = getQuoteIds(state)

    return getQuoteValue(state, vehicleId, quoteId, key, defaultValue)
  },
  getQuoteValue = (state, vId, qId, key, defaultValue) =>
    g(getQuote(state, vId, qId), key, defaultValue),
  getQuoteValueSum = (
    state,
    vId,
    qId,
    key,
    valueKey = 'value',
    isOk = () => true,
  ) =>
    getQuoteValue(state, vId, qId, key, []).reduce(
      (ret, v) => ret + (isOk(v) ? get(v, valueKey, 0) : 0),
      0,
    ),
  getPreviousQuotesForVehicle = (state, vId) => {
    return findAllEntities(
      state,
      e =>
        g(e, '_entityType') === 'quote' &&
        g(e, 'vehicleId') === vId &&
        (g(e, 'shortCode', null) !== null || e.hasOwnProperty('intent')),
    )
  },
  getPreviousQuoteCountForVehicle = (state, vId) =>
    getPreviousQuotesForVehicle(state, vId).length,
  getProgramLookupKey = (state, vId, qId, mode) => {
    let gv = (key, def) =>
      get(
        getGlobalQuoteFilters(state, {
          grayList: ['leaseTerm', 'loanTerm'],
        }),
        key,
        def,
      )

    if (mode === 'cash') return [mode, 0, 0, 0, 0].join('|')

    let creditScore = gv('creditScore', 0),
      term = gv(`${mode}Term`, WILDCARD),
      miles = mode === 'loan' ? 0 : gv('miles', WILDCARD),
      down = gv('down', WILDCARD),
      key = [mode, term, down, creditScore, miles].join('|'),
      program = getProgramByRegEx(state, vId, qId, key)

    return get(program, '_key')
  },
  getBestProgramKey = (state, vId, qId, mode) =>
    getProgramLookupKey(state, vId, qId, mode),
  getBestProgram = (state, vId, qId, mode) => {
    let key = getBestProgramKey(state, vId, qId, mode),
      program = getProgramByKey(state, vId, qId, key)

    return program
  },
  getBestProgramQuoteParams = (state, vId, qId, mode, ns) => {
    let program = getBestProgram(state, vId, qId, mode),
      paramKeys = ['term', 'creditScore', 'miles', 'down']

    return program.isValid
      ? paramKeys.reduce(
          (ret, k) =>
            program[k] && program[k] !== WILDCARD
              ? {
                  ...ret,
                  [`${[ns, k === 'term' ? `${mode}Term` : k]
                    .filter(x => x)
                    .join('.')}`]: program[k],
                }
              : ret,
          {},
        )
      : {}
  },
  getBestProgramValue = (state, vId, qId, mode, valueName, defaultVal = 0) =>
    g(getBestProgram(state, vId, qId, mode), valueName, defaultVal),
  getBestPayment = (state, vId, qId, mode) =>
    getBestProgramValue(state, vId, qId, mode, 'Payment', 0),
  getBestLoanPayment = (state, vId, qId) =>
    getBestPayment(state, vId, qId, 'loan'),
  getBestLeasePayment = (state, vId, qId) =>
    getBestPayment(state, vId, qId, 'lease'),
  getTotal = (state, vId, qId, mode, cKey, vKey = 'Value', filter = v => v) => {
    let program = getBestProgram(state, vId, qId, mode),
      quoteVal = getQuoteValue(state, vId, qId, cKey),
      coll = get(program, cKey) || quoteVal || []

    return coll.filter(filter).reduce((r, c) => r + g(c, vKey, 0), 0)
  },
  getQuoteDirty = (state, vId, qId) => {
    return getQuoteValue(state, vId, qId, 'dirty', false)
  },
  isQuoteDirty = getQuoteDirty,
  getIsLoading = (state, vId, qId) =>
    getQuoteValue(state, vId, qId, 'isLoading', false) ||
    isSqueezeJacketOpen(state),
  getIsGettingPrograms = (state, vId, qId) =>
    getQuoteValue(state, vId, qId, 'gettingPrograms', false) ||
    isSqueezeJacketOpen(state),
  getQuoteByVersion = (state, vId, qId, txn, def = {}) => {
    let e = getQuote(state, vId, qId)

    if (!txn) return get(e, '_latest', {}) // no txn? return the latest version

    return txn && e._versions
      ? get(e._versions.find(v => v.txn === txn), 'quote', {})
      : def
  },
  getCashDealQuote = (state, vId, qId) =>
    get(getQuote(state, vId, qId), `programs[${CASH_PROGRAM_PATH}]`, {}),
  getCashDealTotal = (state, vId, qId) => {
    let isUsed = getIsUsedCar(state, vId),
      showRange = showPaymentRange(state),
      otd = getQuoteValue(
        state,
        vId,
        qId,
        `programs[${CASH_PROGRAM_PATH}]TotalCashDue`,
        0,
      ),
      price = getQuoteValue(state, vId, qId, 'price', 0),
      msrp = getQuoteValue(state, vId, qId, 'msrp', price),
      val = isUsed ? price : msrp

    return showRange ? val : otd
  },
  getSelectedSurveyValues = (state, vId, qId, mode) => {
    let q = getQuote(state, vId, qId)

    let ret = {
      down: get(q, 'down', 0),
      creditScore: get(q, 'creditScore', 0),
      loanTerm: get(q, 'loanTerm', 0),
      leaseTerm: get(q, 'leaseTerm', 0),
      miles: get(q, 'miles', 0),
      term: get(q, `${mode}Term`, 0),
    }

    return ret
  },
  getSelectedProgram = (state, vId, qId, mode) =>
    getProgramsByKey(state, vId, qId, {
      ...getSelectedSurveyValues(state, vId, qId, mode),
      mode,
    }),
  getSelectedProgrammValue = (state, vId, qId, mode, valueName, defaultVal) =>
    g(getBestProgram(state, vId, qId, mode), valueName, defaultVal),
  getSelectableValueSet = (state, vId, qId, valueKey, filters = {}) =>
    getAllSelectableValueSet(state, vId, qId, filters)[valueKey],
  getAllSelectableValueSet = createDeepEqualSelector(
    [
      (state, vId, qId, filters = {}) =>
        getProgramsByKey(state, vId, qId, filters),
    ],
    programsForMode => {
      return Object.keys(KEY_INDICES).reduce((ret, k) => {
        let valueIndex = KEY_INDICES[k]

        return {
          ...ret,
          [k]: Array.from(
            new Set(
              keys(programsForMode)
                .filter(k => k !== CASH_PROGRAM_PATH)
                .map(k => get(k.split('|'), `[${valueIndex}]`))
                .filter(x => x),
            ),
          ).sort(),
        }
      }, {})
    },
  ),
  getAvailableSurveyValues = createSelector(
    [
      (_, vId, qId, mode, valueKey, valuesKey, allowAll, hideAlt) => ({
        vId,
        qId,
        mode,
        valueKey,
        valuesKey,
        allowAll,
        hideAlt,
      }),
      (s, vId, qId) => getQuoteValue(s, vId, qId, 'programs', {}),
      (s, vId, qId, _, valueKey) => getQuoteValue(s, vId, qId, valueKey),
      (s, vId, qId, _, valueKey, valuesKey) =>
        getQuoteValue(s, vId, qId, valuesKey),
      (s, vId, qId, mode) => getBestProgram(s, vId, qId, mode),
      s => getPaymentMods(s),
      s => showPaymentRange(s),
      (s, vId, qId) => getCurrentProgramKey(s, vId, qId),
      (s, vId, qId) => getIsGettingPrograms(s, vId, qId),
    ],
    (
      { mode, valueKey, hideAlt },
      programs,
      _,
      values,
      bestProgram,
      paymentMods,
      showRange,
      currentKey,
      gettingPrograms,
    ) => {
      if (valueKey === 'leaseTerm') mode = 'lease'
      if (valueKey === 'loanTerm') mode = 'loan'

      let currentPayment = get(bestProgram, 'Payment', 0),
        { paymentRoundTo } = paymentMods

      let ret = values.map((v, i) => {
        let newKey = explodeProgramKey(currentKey)

        newKey[valueKey] = v.value
        if (mode === 'loan') newKey['miles'] = 0

        newKey = makeProgramKey(newKey, explodeProgramKey(currentKey))

        let newPayment = get(programs, `[${newKey}].Payment`, 0),
          isValid = newPayment !== 0,
          selected = newKey === currentKey,
          available = !gettingPrograms && !selected && isValid,
          diff = gettingPrograms ? 0 : newPayment - currentPayment,
          up = available && diff > 0,
          down = available && diff < 0,
          even = available && diff === 0

        diff =
          diff !== 0 && showRange ? roundPayment(diff, paymentRoundTo) : diff

        let surveryOption = {
          ...v,
          diff,
          diffLabel: (
            <span
              className={c('coord', {
                up,
                down,
                even,
                'not-available': !available && !selected && !gettingPrograms,
              })}
            >
              {v.label || ' '}
              <small>
                <>
                  {gettingPrograms && <Icon icon="circle-notch" spin />}
                  {!gettingPrograms && selected && <Icon icon="check-square" />}
                  {!gettingPrograms && !selected && !available && (
                    <Icon icon="times" />
                  )}
                  {up && <Icon icon="arrow-up" />}
                  {down && <Icon icon="arrow-down" />}
                  {even && <Icon icon="equals" />}{' '}
                  {available && diff !== 0 && moneySuperShort(Math.abs(diff))}
                </>
              </small>
            </span>
          ),
          title:
            !available && !hideAlt
              ? 'No programs are availble for this option when combined with the other selected options'
              : '',
        }

        return surveryOption
      })

      return ret
    },
  ),
  quoteHasPrograms = (state, vId, qId) =>
    !isEmpty(getQuoteValue(state, vId, qId, 'programs')),
  quoteHasLoans = (state, vId, qId) =>
    !isEmpty(getMatchingProgramKeys(state, vId, qId, { mode: 'loan' })),
  quoteHasLeases = (state, vId, qId) =>
    !isEmpty(getMatchingProgramKeys(state, vId, qId, { mode: 'lease' })),
  getValidModes = (state, vId, qId) => {
    return {
      lease: quoteHasLeases(state, vId, qId),
      loan: quoteHasLoans(state, vId, qId),
      cash: quoteHasCash(state, vId, qId),
    }
  },
  getRebateModeCheck = mode => (mode === 'loan' ? 'retail' : 'lease'),
  getAutoRebateIds = (state, vId, qId, mode) =>
    (mode
      ? getBestProgramValue(state, vId, qId, mode, 'AppliedRebate', [])
      : []
    )
      .map(r => (r.Value ? r.ID : null))
      .filter(x => x),
  getSelectedRebateIds = (state, vId, qId) => {
    let selectedIds = getQuoteValue(state, vId, qId, 'selectedRebateIds', []),
      autoIds = []

    return uniq([...selectedIds, ...autoIds])
  },
  getCompatCategoriesForRebate = (state, vId, qId, rebateId) => {
    let compats = getQuoteValue(
        state,
        vId,
        qId,
        'rebateInfo.GeneralCompatibilities',
        [],
      ),
      catIds = (compats || []).reduce(
        (ret, c) =>
          (get(c, 'CompatibilityList', []) || []).includes(rebateId)
            ? [...ret, c.RebateID]
            : ret,
        [],
      ),
      rebates = getQuoteValue(state, vId, qId, 'rebateInfo.Rebates', []),
      ret = uniq(
        rebates
          .map(r => (catIds.includes(r.ID) ? r.NameDisplay : null))
          .filter(x => x),
      )

    return ret
  },
  getOptionCodeSet = (state, vId, qId) => {
    return Array.from(
      new Set(
        getRebatesForVehicle(state, vId, qId).reduce(
          (ret, r) => [...ret, ...get(r, 'Option', [])],
          [],
        ),
      ),
    )
  },
  generateSelectedRebateIds = rebateInfo => {
    let currentCustomerTypeID = get(rebateInfo, 'CustomerTypeID')

    return get(rebateInfo, 'Rebates', [])
      .filter(
        r =>
          r.Selected &&
          (get(r, 'CustomerTypes') || [])
            .map(ct => ct.ID)
            .includes(currentCustomerTypeID),
      )
      .map(r => r.ID)
  },
  getRebatesForVehicle = (state, vId, qId, mode, filters = {}) => {
    let rebates = getQuoteValue(state, vId, qId, 'rebateInfo.Rebates', []),
      selectedRebateIds = getSelectedRebateIds(state, vId, qId, mode),
      selectedRebateValues = getQuoteValue(
        state,
        vId,
        qId,
        'selectedRebateValues',
        {},
      ),
      autoIds = getAutoRebateIds(state, vId, qId, mode),
      { selected, auto, customer, dealer, available } = filters,
      lenderCode = getSelectedProgrammValue(
        state,
        vId,
        qId,
        mode,
        'LenderCode',
      ),
      modeCheck = getRebateModeCheck(mode),
      isOk = r =>
        !r.isFake &&
        !r.InfoPurposeOnly &&
        ((!selected || (selected && r.Selected)) &&
          (!auto || (auto && r.isAuto)) &&
          (!customer || (customer && r.isCustomer)) &&
          (!dealer || (dealer && r.isDealer)) &&
          (!available || r.isAvailable)),
      isAvailable = r =>
        (r.LenderCode === lenderCode || r.LenderCode === '_ANY_') &&
        !isEmpty(get(r, 'Value.Values')) &&
        (get(r, 'Value.Values').find(v => v.Type === modeCheck) ||
          get(r, 'Value.Values').find(v => v.Type === 'any')),
      rebateMapper = r => {
        let isFake =
            isEmpty(r.CustomerTypes) ||
            (!getWritePermission(state, 'deal.fields/profit') &&
              r.ManualValueInputRequired &&
              !selectedRebateValues[r.ID]),
          isSelected = selectedRebateIds.includes(r.ID) || r.AvailableToAll

        let ret = {
          ...r,
          isFake,
          Selected: isSelected && !isFake,
          isAuto: autoIds.includes(r.ID),
          isAvailable: isAvailable(r),
          isCustomer: !isFake && r.RecipientType === 'customer',
          isDealer: !isFake && r.RecipientType === 'dealer',
          compats: getCompatCategoriesForRebate(state, vId, qId, r.ID),
        }

        if (r.ManualValueInputRequired) {
          r.manualValue =
            getQuoteValue(state, vId, qId, `selectedRebateValues.${r.ID}`, 0) ||
            get(
              (get(r, 'Value.Values', []) || []).find(v => v.Type === 'any'),
              0,
            ) ||
            r.manualValue ||
            0
        }

        return ret
      }

    rebates = rebates.reduce((ret, r) => {
      let children = !isEmpty(r.CCRIncrementals)
        ? r.CCRIncrementals.map(rebateMapper)
        : []

      return [...ret, rebateMapper(r), ...children]
    }, [])

    return rebates.filter(isOk)
  },
  getRebateCount = (state, vId, qId, category) =>
    getRebatesForCategory(state, vId, qId, category).length,
  getRebatesForCategory = (state, vId, qId, category, rebates) => {
    rebates =
      rebates || getQuoteValue(state, vId, qId, 'rebateInfo.Rebates', [])

    let { ID, parent = {} } = category,
      parentId = get(parent, 'ID'),
      ret =
        rebates.filter(
          r =>
            !r.InfoPurposeOnly &&
            ((!parentId && r.CategoryID === ID) ||
              (parentId &&
                r.SubcategoryID === ID &&
                r.CategoryID === parentId)),
        ) || []

    return ret
  },
  getPreselectedRebates = (state, vId, qId, categories = [], rebates = []) => {
    let selectedRebateIds = categories.reduce(
      (ret, rc) => [
        ...ret,
        ...getRebatesForCategory(state, vId, qId, rc, rebates).map(r => r.ID),
      ],
      [],
    )

    return selectedRebateIds
  },
  getRebateCustomerTypes = (state, vId, qId) => {
    let customerTypes = getQuoteValue(
      state,
      vId,
      qId,
      'rebateInfo.CustomerTypes',
      [],
    )

    return customerTypes.reduce((ret, ct) => ({ ...ret, [ct.ID]: ct.Name }), {})
  },
  getRebatesForContractDetails = (
    state,
    vId,
    qId,
    mode,
    isDealerCash = false,
  ) => {
    let rebateValuesComplete = getBestProgramValue(
        state,
        vId,
        qId,
        mode,
        'programInfo.RebateValues',
        [],
      ),
      rebateValuesLimited = getBestProgramValue(
        state,
        vId,
        qId,
        mode,
        'AppliedRebate',
        [],
      ),
      rebateValues = isEmpty(rebateValuesComplete)
        ? rebateValuesLimited
        : rebateValuesComplete,
      allRebates = getRebatesForVehicle(state, vId, qId),
      getAttr = (id, attr, def) =>
        get(allRebates.find(r => r.ID === id), attr, def)

    if (isEmpty(rebateValues)) return []

    let ret = rebateValues
      .reduce((ret, r) => {
        let rId = r.RebateID || r.ID

        return [
          ...ret,
          {
            name: `${getAttr(
              rId,
              'NameDisplay',
              `Rebate ID: ${rId}`,
            )} ${getAttr(rId, 'Number')}`,
            value: r.Value,
            isDealerCash: getAttr(rId, 'RecipientType') === 'dealer',
          },
        ]
      }, [])
      .filter(r => r.value > 0 && isDealerCash === r.isDealerCash)

    return ret
  },
  getCashTotalRebateSum = (state, vId, qId) => {
    return (
      getQuoteValue(
        state,
        vId,
        qId,
        `programs[${CASH_PROGRAM_PATH}].TotalRebate`,
        0,
      ) ||
      getQuoteValue(state, vId, qId, `programs[${CASH_PROGRAM_PATH}].Rebate`, 0)
    )
  },
  getAppliedRebateSum = (state, vId, qId, mode) => {
    return mode === 'cash'
      ? getCashTotalRebateSum(state, vId, qId)
      : getBestProgramValue(state, vId, qId, mode, 'AppliedRebate', []).reduce(
          (ret, r) => ret + get(r, 'Value', 0),
          0,
        )
  },
  getAppliedRebateSumForProgram = program => {
    return get(program, 'AppliedRebate', []).reduce(
      (ret, r) => ret + get(r, 'Value', 0),
      0,
    )
  },
  getRebateSumForContractDetails = (state, vId, qId, mode, isDealerCash) =>
    getRebatesForContractDetails(state, vId, qId, mode, isDealerCash).reduce(
      (ret, r) => ret + r.value,
      0,
    ),
  getCustomerRebateSumForDealTuner = (state, vId, qId, mode) => {
    return mode === 'cash'
      ? getBestProgramValue(state, vId, qId, mode, 'Rebate')
      : getRebateSumForContractDetails(state, vId, qId, mode)
  },
  getRebatesSum = getRebateSumForContractDetails,
  getRebateValueForProgram = (rebate, mode) => {
    if (typeof rebate.Value === 'number') return rebate.Value
    if (!mode) return 'Varies'

    let modeCheck = getRebateModeCheck(mode),
      val =
        (get(rebate, 'Value.Values', []) || []).find(
          v => v.Type === modeCheck || v.Type === 'any',
        ) || {}

    return val.Value
  },
  getRebateById = (state, vId, qId, rId) =>
    getQuoteValue(state, vId, qId, 'rebateInfo.Rebates', []).find(
      r => r.ID.toString() === rId.toString(),
    ),
  getRebateValues = (rebate = {}) => {
    if (typeof rebate.Value === 'number') return rebate.Value

    return (get(rebate, 'Value.Values', []) || [])
      .map(v => v.Value)
      .filter(v => v)
  },
  getMinRebateValue = (rebate = {}) => {
    return Math.min(getRebateValues(rebate))
  },
  getMaxRebateValue = (rebate = {}) => {
    return Math.max(getRebateValues(rebate))
  },
  getRebateValue = getRebateValueForProgram,
  getFees = (state, vId, qId, mode) =>
    getQuoteValue(state, vId, qId, 'fees', []).filter(f => f[mode]),
  getValidTrades = (state, vId, qId) =>
    getQuoteValue(state, vId, qId, 'fees', []).filter(
      t => t.allowance || t.payoff,
    ),
  getSelectedAdditions = (state, vId, qId) =>
    getQuoteValue(state, vId, qId, 'additions', []).filter(a => a.include),
  getSelectedWarranties = (state, vId, qId) =>
    getQuoteValue(state, vId, qId, 'warranties', []).filter(a => a.include),
  getSelectedMaintenancePlans = (state, vId, qId) =>
    getQuoteValue(state, vId, qId, 'maintenancePlans', []).filter(
      a => a.include,
    ),
  getValueFromProgramKey = (programKey = '', valueKey = '') =>
    programKey.split('|')[KEY_INDICES[valueKey]],
  getCurrentProgramKey = (state, vId, qId, mode) => {
    return makeProgramKey(getQuote(state, vId, qId))
  },
  makeProgramKey = (program = {}, fallbacks = {}) => {
    let keyData = Object.keys(program).reduce(
      (ret, k) => ({
        ...ret,
        [k]: typeof program[k] !== undefined ? program[k] : fallbacks[k],
      }),
      fallbacks,
    )

    return [
      keyData.mode,
      keyData[`${keyData.mode}Term`] || keyData.term,
      keyData.down,
      keyData.creditScore,
      keyData.mode === 'lease' ? keyData.miles : 0, // loans wont have miles
    ].join('|')
  },
  explodeProgramKey = programKey => {
    let mode = getValueFromProgramKey(programKey, 'mode'),
      ret = {
        mode,
        down: getValueFromProgramKey(programKey, 'down'),
        creditScore: getValueFromProgramKey(programKey, 'creditScore'),
        term: getValueFromProgramKey(programKey, `${mode}Term`),
        [`${mode}Term`]: getValueFromProgramKey(programKey, `${mode}Term`),
      }

    if (mode === 'lease')
      ret.miles = getValueFromProgramKey(programKey, 'miles')

    return ret
  },
  getPaymentProps = createDeepEqualSelector(
    [
      (_, op) => op,
      (s, op) =>
        op.programKey
          ? get(
              getProgramByKey(s, op.vehicleId, op.quoteId, op.programKey),
              'Payment',
            )
          : getBestPayment(s, op.vehicleId, op.quoteId, op.mode),
      (s, op) => getCashDealTotal(s, op.vehicleId, op.quoteId, op.range),
      (s, op) => getQuoteDirty(s, op.vehicleId, op.quoteId),
      s => getHasMdrive(s),
    ],
    (op, payment, cashDealTotal, dirty, hasMdrive) => {
      return {
        ...op,
        dirty,
        hasMdrive,
        payment: op.payment || (op.mode === 'cash' ? cashDealTotal : payment),
      }
    },
  ),
  getCombinedAdds = (state, vId, qId) => {
    let hasMdrive = getHasMdrive(state),
      additions = hasMdrive
        ? []
        : getQuoteValue(state, vId, qId, 'additions', []),
      warranties = getQuoteValue(state, vId, qId, 'warranties', []), // always have warranties, even with mdrive
      plans = hasMdrive
        ? []
        : getQuoteValue(state, vId, qId, 'maintenancePlans', [])

    return [...additions, ...warranties, ...plans]
  },
  getProgramChartFilterProps = createSelector(
    [
      (_, op) => ({
        allPrograms: op.allPrograms,
        filterGroups: op.filterGroups,
      }),
      (s, op) => getAllSelectableValueSet(s, op.vehicleId, op.quoteId),
    ],
    ({ allPrograms, filterGroups }, allSelectableVals) => {
      let numSorter = (a, b) => parseInt(a, 10) - parseInt(b, 10),
        selectableVals = {
          mode: allSelectableVals['mode'].filter(m => m !== 'cash'),
          down: allSelectableVals['down'].sort(numSorter),
          term: allSelectableVals['term'].sort(numSorter),
          creditScore: allSelectableVals['creditScore']
            //.filter(s => s > 0)
            .sort(numSorter),
          miles: allSelectableVals['miles'].filter(m => m > 0).sort(numSorter),
          Lender: Array.from(
            new Set(Object.values(allPrograms || {}).map(p => p.Lender)), //eslint-disable-line
          )
            .filter(p => p)
            .sort(),
        }
      const valueReducer = filterGroup => (ret, m) => [
        ...ret,
        {
          value: m,
          selected: get(filterGroup, 'selected', []).includes(m),
        },
      ]
      return {
        filterGroups: Object.keys(filterGroups)
          .filter(k => selectableVals[k].length > 0)
          .reduce(
            (ret, k) => ({
              ...ret,
              [k]: {
                ...filterGroups[k],
                filterVals: selectableVals[k].reduce(
                  valueReducer(filterGroups[k]),
                  [],
                ),
              },
            }),
            {},
          ),
      }
    },
  ),
  getAddsPrice = (state, vehicleId, quoteId) => {
    return (
      getQuoteValueSum(
        state,
        vehicleId,
        quoteId,
        'additions',
        'price',
        a => a.include,
      ) +
      getQuoteValueSum(
        state,
        vehicleId,
        quoteId,
        'maintenancePlans',
        'price',
        a => a.include,
      ) +
      getQuoteValueSum(
        state,
        vehicleId,
        quoteId,
        'warranties',
        'price',
        a => a.include,
      )
    )
  },
  getAddsProfit = (state, vehicleId, quoteId) => {
    let additionsFields = ['additions', 'maintenancePlans', 'warranties'],
      priceSum = additionsFields.reduce(
        (ret, f) =>
          getQuoteValueSum(
            state,
            vehicleId,
            quoteId,
            f,
            'price',
            a => a.include,
          ) + ret,
        0,
      ),
      costSum = additionsFields.reduce(
        (ret, f) =>
          getQuoteValueSum(
            state,
            vehicleId,
            quoteId,
            f,
            'cost',
            a => a.include,
          ) + ret,
        0,
      )

    return priceSum - costSum
  },
  quoteHasLease = (state, vehicleId, quoteId) =>
    get(getBestProgram(state, vehicleId, quoteId, 'lease'), 'isValid'),
  quoteHasLoan = (state, vehicleId, quoteId) =>
    get(getBestProgram(state, vehicleId, quoteId, 'loan'), 'isValid'),
  quoteHasCash = (state, vehicleId, quoteId) =>
    get(getBestProgram(state, vehicleId, quoteId, 'cash'), 'isValid'),
  getSelectedQuoteSection = (state, vehicleId, quoteId, def) =>
    getQuoteValue(state, vehicleId, quoteId, 'customer.selectedSection', def),
  getQuoteTrackerStats = (state, vId, qId, addendum) => {
    let gqv = v => getQuoteValue(state, vId, qId, v),
      showRange = showPaymentRange(state, vId, qId),
      { paymentRangePercent, paymentRoundTo } = getPaymentMods(state),
      leasePayment = getBestLeasePayment(state, vId, qId),
      loanPayment = getBestLoanPayment(state, vId, qId),
      cashPrice = getCashDealTotal(state, vId, qId)

    let ret = {
      vehicleId: vId,
      quoteId: qId,
      payment: {
        leaseTerm: gqv(`leaseTerm`),
        loanTerm: gqv(`loanTerm`),
        creditScore: gqv('creditScore'),
        mode: gqv('mode'),
        down: gqv('down'),
        miles: gqv('miles'),
        desiredPayment: gqv('desiredPayment', 0),
        recommendedDownPayment: gqv('recommendedDownPayment', 0),
        dirty: getQuoteDirty(state, vId, qId),
        showRange,
        leasePayment,
        loanPayment,
        cashPrice,
        paymentRangePercent,
        paymentRoundTo,
      },
      ...addendum,
    }

    return ret
  },
  isQuotePersisted = (state, vehicleId, quoteId) =>
    !!getQuoteValue(state, vehicleId, quoteId, 'shortCode'),
  getPassNumber = (state, vehicleId, quoteId) =>
    getQuoteValue(state, vehicleId, quoteId, '_versions', []).length,
  getPassDate = (state, vehicleId, quoteId) =>
    getQuoteValue(state, vehicleId, quoteId, '_timestamp'),
  getStartingQuoteIdForVehicle = (state, vehicleId) =>
    get(getEntity(state, vehicleId), 'quoteId'),
  getCalculatedDesiredFrontProfit = (state, vId, quote) => {
    let isNew = get(getEntity(state, vId), 'type') === 'New',
      price = get(quote, 'price', 0),
      cost = get(quote, 'cost', 0),
      invoice = get(quote, 'invoice', 0)

    return price - (isNew ? invoice : cost)
  },
  getLocks = (state, vId, qId) => {
    return {
      creditScore: parseInt(getQuoteValue(state, vId, qId, 'creditScore'), 10),
      down: parseInt(getQuoteValue(state, vId, qId, 'down'), 10),
    }
  },
  filterProgramsByLocks = (state, vId, qId, locks = {}) => {
    let programs = getQuoteValue(state, vId, qId, 'programs', {}),
      lkz = Object.keys(locks)

    let ret = Object.keys(programs).filter(k => {
      let expk = explodeProgramKey(k)

      return (
        !lkz.length ||
        lkz.every(lk => isNull(locks[lk]) || expk[lk] === locks[lk].toString())
      )
    })

    return ret
  },
  findNearestProgram = (state, vId, qId, desiredPayment, filters = {}) => {
    let mode = getQuoteValue(state, vId, qId, 'mode', 'lease'),
      programs = getPrograms(state, vId, qId, filters),
      bestDelta = null,
      bestMatch

    desiredPayment =
      desiredPayment ||
      getQuoteValue(state, vId, qId, 'desiredPayment', 0) ||
      getBestPayment(state, vId, qId, mode)

    if (!desiredPayment) return {}

    Object.keys(programs).forEach(k => {
      let program = programs[k],
        currentPayment = get(program, 'Payment'),
        currentDelta = Math.abs(currentPayment - desiredPayment)

      if (bestDelta === null || currentDelta < bestDelta) {
        bestDelta = currentDelta
        bestMatch = { key: k, ...program, ...explodeProgramKey(k) }
      }
    })

    return bestMatch || {}
  },
  findBestProgramBy = (
    state,
    vehicleId,
    quoteId,
    finder = () => {},
    locks = {},
  ) => {
    let programs = getQuoteValue(state, vehicleId, quoteId, 'programs'),
      bestProgram = {},
      bestVal = null,
      lkz = Object.keys(locks)

    Object.keys(programs).forEach(k => {
      let expk = explodeProgramKey(k),
        p = { ...programs[k], ...expk },
        currentVal = finder(p),
        lockOk =
          !lkz.length ||
          lkz.every(lk => locks[lk] === null || p[lk] === locks[lk].toString())

      if (lockOk && currentVal && (bestVal === null || currentVal < bestVal)) {
        bestVal = currentVal
        bestProgram = p
      }
    })

    return bestProgram
  },
  getSuperlativePrograms = (state, vehicleId, quoteId, locks = {}) => ({
    'Best Residual': findBestProgramBy(
      state,
      vehicleId,
      quoteId,
      p => (p.ResidualPercent ? 100 - p.ResidualPercent : null),
      locks,
    ),
    'Best Financing': findBestProgramBy(
      state,
      vehicleId,
      quoteId,
      p => (p.mode === 'loan' ? p.Payment * p.term - p.AmountFinanced : null),
      locks,
    ),
    'Best Loan Rate': findBestProgramBy(
      state,
      vehicleId,
      quoteId,
      p => (p.mode === 'loan' ? p.Rate : null),
      locks,
    ),
    'Best Lease Rate': findBestProgramBy(
      state,
      vehicleId,
      quoteId,
      p => (p.mode === 'lease' ? p.Rate : null),
      locks,
    ),
    'Most Headroom': findBestProgramBy(
      state,
      vehicleId,
      quoteId,
      p => p.MaxAdvance,
      locks,
    ),
  }),
  getSliderMarkers = (state, vehicleId, quoteId, locks = {}) => {
    let programs = getSuperlativePrograms(state, vehicleId, quoteId, locks),
      markers = Object.keys(programs).reduce((ret, k) => {
        let payment = get(programs, `${k}.Payment`, 0)

        return payment > 0 ? { ...ret, [payment]: k } : ret
      }, {})

    return markers
  },
  getProgramFilterHasher = (state, vId, qId, filters = {}) =>
    JSON.stringify({
      ...filters,
      vId,
      qId,
      lastPull: getQuoteValue(state, vId, qId, 'lastPull', 0),
    }),
  getMatchingProgramKeys = memoize((state, vId, qId, filters) => {
    info('Program Cache Miss', JSON.stringify({ vId, qId, ...filters }))
    let pkz = getProgramKeysFromQuote(state, vId, qId, 'programs')

    let ret = pkz.filter(k =>
      keys(filters).every(fk => {
        return explodeProgramKey(k)[fk] == filters[fk] // eslint-disable-line
      }),
    )
    return ret
  }, getProgramFilterHasher),
  getProgramKeysFromQuote = memoize(
    (state, vId, qId) =>
      Object.keys(getQuoteValue(state, vId, qId, 'programs', {})),
    getProgramFilterHasher,
  ),
  getProgramsFromQuote = (state, vId, qId) =>
    getQuoteValue(state, vId, qId, 'programs'),
  makeProgramDB = (state, vId, qId) => {
    let programKeys = getProgramKeysFromQuote(state, vId, qId),
      db = {}

    programKeys.forEach(pk => {
      let expk = explodeProgramKey(pk)

      set(
        db,
        [expk.mode, expk.down, expk.credit, expk.miles, expk.term].join('.'),
        pk,
      )
    }, {})

    return db
  },
  getProgramsByKey = (state, vId, qId, filters) => {
    let matches = getMatchingProgramKeys(state, vId, qId, filters),
      programs = getProgramsFromQuote(state, vId, qId)

    return matches.reduce(
      (ret, k) => ({
        ...ret,
        [k]: {
          _key: k,
          ...explodeProgramKey(k),
          ...programs[k],
          isValid: !isEmpty(programs[k]),
        },
      }),
      {},
    )
  },
  getMatchingPrograms = getProgramsByKey,
  checkIsValid = (program = {}) => {
    let { Payment = 0, TotalCashDue = 0 } = program

    return !isEmpty(program) && (Payment > 0 || TotalCashDue > 0)
  },
  getProgramByDirectKey = (state, vId, qId, key) => {
    let program = {
      ...getQuoteValue(state, vId, qId, `programs.${key}`),
    }

    return program
  },
  getProgramByKey = (state, vId, qId, key) => {
    let explodedKey = explodeProgramKey(key),
      hasWildCards = values(explodedKey).some(v => v === WILDCARD),
      getterFunc = hasWildCards ? getProgramByRegEx : getProgramByDirectKey,
      program = getterFunc(state, vId, qId, key),
      ret = {
        _key: key,
        ...explodeProgramKey(key),
        ...program,
        isValid: checkIsValid(program),
      }

    return ret
  },
  getProgramsByRegEx = (state, vId, qId, key) => {
    let expKey = explodeProgramKey(key),
      filters = keys(expKey).reduce(
        (ret, k) =>
          !expKey[k] || expKey[k] === WILDCARD
            ? ret
            : { ...ret, [k]: expKey[k] },
        {},
      )

    return getProgramsByKey(state, vId, qId, filters)
  },
  getProgramByRegEx = (
    state,
    vId,
    qId,
    key,
    chooserFunc = p => get(values(p), 0),
  ) => {
    let programs = getProgramsByRegEx(state, vId, qId, key)

    return chooserFunc(programs)
  },
  isProgramExpired = (state, vId, qId, mode, p) => {
    let program = p || getBestProgram(state, vId, qId, mode),
      { ExpirationDate } = program

    return ExpirationDate && moment().isSameOrAfter(ExpirationDate)
  },
  checkProgram = (state, vId, qId, programKey, filters = {}) => {
    if (isEmpty(filters)) return true

    let program = {
      ...getQuoteValue(state, vId, qId, `programs[${programKey}]`),
      ...explodeProgramKey(programKey),
    }

    return Object.keys(filters).every(fk => {
      return (filters[fk] || []).some(f => f === program[fk])
    })
  },
  convertFilterGroupsToFilters = (filterGroups = {}) => {
    let filters = Object.keys(filterGroups).reduce((ret, k) => {
      return !isEmpty(filterGroups[k].selected)
        ? {
            ...ret,
            [k]: filterGroups[k].selected,
          }
        : ret
    }, {})

    return filters
  },
  getPrograms = (state, vId, qId, filters) => {
    let programs = getQuoteValue(state, vId, qId, 'programs')

    if (isEmpty(filters)) return programs

    let filteredPrograms = Object.keys(programs).reduce((ret, k) => {
      return checkProgram(state, vId, qId, k, filters)
        ? { ...ret, [k]: programs[k] }
        : ret
    }, {})

    return filteredPrograms || {}
  },
  getPaymentSliderMarkers = (state, vId, qId, filters) => {
    let programs = getPrograms(state, vId, qId, filters),
      markers = Object.keys(programs).reduce((ret, k) => {
        return programs[k].Payment
          ? {
              ...ret,
              [programs[k].Payment]: k,
            }
          : ret
      }, {})

    return markers
  },
  getPaymentRange = (state, vId, qId, filters) => {
    let payments = Object.keys(
      getPaymentSliderMarkers(state, vId, qId, filters),
    ).map(k => parseFloat(k))

    return [Math.floor(Math.min(...payments)), Math.ceil(Math.max(...payments))]
  },
  isPreparedQuote = (state, vId, qId) => {
    let preparedQId = get(state, `[${STATE_KEY}].shortCodeQuoteId`),
      intent = getQuoteValue(state, vId, qId, 'intent')

    return !qId || preparedQId === qId || !!intent
  },
  getBestAllAroundProgram = (state, vId, qId) => {
    let pf = parseFloat,
      creditScore = getQuoteValue(state, vId, qId, 'creditScore'),
      price = getQuoteValue(state, vId, qId, 'price', 0),
      kz = filterProgramsByLocks(state, vId, qId, { creditScore }),
      programs = getQuoteValue(state, vId, qId, 'programs'),
      bestKeys = orderBy(kz, [
        k => pf(get(programs, `${k}.Profit`, 0)) * -1,
        k => {
          let { term, down } = explodeProgramKey(k),
            payment = pf(get(programs, `${k}.Payment`, 0))

          let ret = (payment * pf(term)) / price + pf(down)
          return ret
        },
        k => pf(get(programs, `${k}.term`, 0)) * -1,
        k => getWarrantyCoverageForProgram(state, vId, programs[k]),
        k => pf(get(programs, `${k}.MaxAdvance`, 0)) * -1,
      ])

    return getProgramByKey(state, vId, qId, bestKeys[0])
  },
  getWarrantyCoverageBreakdown = (state, vId, qId, mode) => {
    let program = getBestProgram(state, vId, qId, mode),
      warrantyInfo = getWarrantyInfo(state, vId),
      f = parseFloat,
      { term, miles } = program,
      mileage = !miles ? 0 : parseFloat(miles.replace(',', '')),
      years = parseFloat(term) / 12

    let coverage = Object.keys(warrantyInfo).reduce((ret, k) => {
      let warranty = warrantyInfo[k]

      return {
        ...ret,
        [k]:
          f(warranty.years) >= years &&
          (warranty.miles === 'Unlimited' ||
            f(warranty.miles.replace(',', '')) >= mileage),
      }
    }, {})

    return coverage
  },
  getWarrantyCoverageForProgram = (state, vId, program) => {
    let warrantyInfo = getWarrantyInfo(state, vId),
      f = parseFloat,
      { term, miles } = program,
      mileage = !miles ? 0 : parseFloat(miles.replace(',', '')),
      years = parseFloat(term) / 12

    let coverage = Object.keys(warrantyInfo).every(k => {
      let warranty = warrantyInfo[k]

      return (
        f(warranty.years) >= years &&
        (warranty.miles === 'Unlimited' ||
          f(warranty.miles.replace(',', '')) >= mileage)
      )
    })

    return coverage
  },
  getResidualValuesForTerms = (state, vId, qId) => {
    let programs = getQuoteValue(state, vId, qId, 'programs'),
      msrp = getQuoteValue(state, vId, qId, 'price'),
      pf = parseFloat

    return Object.keys(programs).reduce((ret, k) => {
      let p = programs[k],
        { term } = explodeProgramKey(k),
        residualValue = pf(p.ResidualPercent / 100) * pf(msrp)

      if (!p.ResidualPercent) return ret

      ret[term] = [...(ret[term] || []), residualValue]

      return ret
    }, {})
  },
  getPredictedEndOfTermValues = (state, vId, qId) => {
    let values = getResidualValuesForTerms(state, vId, qId, 'programs'),
      msrp = getQuoteValue(state, vId, qId, 'price')

    return Object.keys(values).reduce(
      (ret, k) => ({ ...ret, [k]: mean(values[k]) }),
      { 0: msrp },
    )
  },
  getEndOfTermValueRanges = (state, vId, qId) => {
    let values = getResidualValuesForTerms(state, vId, qId, 'programs'),
      msrp = getQuoteValue(state, vId, qId, 'price')

    return Object.keys(values).reduce(
      (ret, k) => ({
        ...ret,
        [k]: { high: Math.max(...values[k]), low: Math.min(...values[k]) },
      }),
      { 0: { high: msrp, low: msrp } },
    )
  },
  getTradeEquity = (state, vId, qId) => {
    let allowances = getQuoteValueSum(state, vId, qId, 'trades', 'allowance'),
      payoffs = getQuoteValueSum(state, vId, qId, 'trades', 'payoff')

    return allowances - payoffs
  },
  getAdditionsTotalForContractDetails = (state, vId, qId) => {
    let combinedAdds = getCombinedAdds(state, vId, qId).filter(c => c.include)

    return combinedAdds.reduce((ret, c) => ret + c.price, 0)
  },
  getAdditionItemsForContractDetails = (state, vId, qId) => {
    let combinedAdds = getCombinedAdds(state, vId, qId).filter(c => c.include)

    return combinedAdds.map(f => ({
      name: f.name,
      value: () => money(f.price),
      className: 'indent',
    }))
  },
  getFeeItemsForContractDetails = (state, vId, qId, mode) => {
    let fees = getFees(state, vId, qId, mode === 'lease' ? 'lease' : 'loan')

    return fees.map(f => ({
      name: f.description,
      value: () => money(f.value),
    }))
  },
  getRebateItemsForContractDetails = (
    state,
    vId,
    qId,
    mode,
    showAsNeg = true,
    includeCta = false,
  ) => {
    let selectedRebates = getRebatesForContractDetails(state, vId, qId, mode)

    return (
      selectedRebates.map(r => ({
        name: r.name,
        value: () => money(r.value * (showAsNeg ? -1 : 1)),
        ctaOptions: includeCta ? (_p, _q, { mode, isKnownCustomer }) => {
          return {
            pageStateAddition: {addRebate: mode},
            label: 'View All Incentives',
          }
        } : undefined,
      })) || []
    )
  },
  getDiscountTotalForContractDetails = (state, vId, qId) => {
    return getQuoteValue(state, vId, qId, 'discounts', []).reduce(
      (ret, c) => ret + (c.include ? c.amount : 0),
      0,
    )
  },
  getDiscountItemsForContractDetails = (state, vId, qId) => {
    let selectedDiscounts = getQuoteValue(
      state,
      vId,
      qId,
      'discounts',
      [],
    ).filter(d => d && d.include)

    return selectedDiscounts.map(r => ({
      name: r.name,
      value: () => money(r.amount * -1),
    }))
  },
  getDealerCashItemsForContractDetails = (
    state,
    vId,
    qId,
    mode,
    showAsNeg = true,
  ) => {
    let selectedDealerCash = getRebatesForContractDetails(
      state,
      vId,
      qId,
      mode,
      true,
    )

    return selectedDealerCash.map(r => ({
      name: r.name,
      value: () => money(r.value * (showAsNeg ? -1 : 1)),
    }))
  },
  getDealerCashProfit = (state, vId, qId, mode) => {
    let dealerIncentivesReducePrice = getQuoteValue(
      state,
      vId,
      qId,
      'dealerIncentivesReducePrice',
    )

    return dealerIncentivesReducePrice
      ? 0
      : getRebatesSum(state, vId, qId, mode, true)
  },
  getPaidReserveProfit = (state, vId, qId, mode) => {
    let paidReserveReducesPrice = getQuoteValue(
      state,
      vId,
      qId,
      'paidReserveReducesPrice',
    )

    return paidReserveReducesPrice
      ? 0
      : getBestProgramValue(state, vId, qId, mode, 'PaidReserve')
  },
  doesWarrantyRunOut = (state, vId, qId, mode, warranty) => {
    let { term, miles } = getBestProgram(state, vId, qId, mode),
      actualMileage = miles * (term / 12),
      wMonths = parseInt(warranty.months, 10),
      wMiles = parseInt(warranty.miles, 10)

    return (
      !!(wMonths && term > wMonths) ||
      !!(mode === 'lease' && wMiles && actualMileage > wMiles)
    )
  },
  doesAnyWarrantyRunOut = (state, vId, qId, mode) => {
    let warranties = getDataOneWarranties(state, vId) || []

    return warranties.some(w => doesWarrantyRunOut(state, vId, qId, mode, w))
  },
getStartingModeRaw = quote => {
  if (isEmpty(quote.programs)) return null

  let { programs = {} } = quote,
      leaseProgramKey = makeProgramKey({...quote, mode: 'lease'}),
      loanProgramKey = makeProgramKey({...quote, mode: 'loan'}),

      dict = Object.keys(programs).reduce((ret, k) => {
        ret[explodeProgramKey(k).mode] = true
        if(k === leaseProgramKey) ret.hasCurrentLeaseProgram = true
        if(k === loanProgramKey) ret.hasCurrentLoanProgram = true
        return ret
      }, {}),
      currentMode = quote.mode,
      modeOkAsIs = currentMode && dict[currentMode],
      newMode = 'cash'

  if (modeOkAsIs) return currentMode

  if (dict.hasCurrentLeaseProgram){
    newMode = 'lease'
  }else if (dict.hasCurrentLoanProgram){
    newMode = 'loan'
  }else if (dict.loan){
    newMode = 'loan'
  }else if (dict.lease) {
    newMode = 'lease'
  }
  return newMode
},
  getStartingMode = (state, vId, qId, mode) => {
    let currentMode = mode || getQuoteValue(state, vId, qId, 'mode'),
      hasLease = quoteHasLease(state, vId, qId),
      hasLoan = quoteHasLoan(state, vId, qId),
      hasCash = quoteHasCash(state, vId, qId),
      modeDict = {
        lease: hasLease,
        loan: hasLoan,
        cash: hasCash,
      },
      modeOkAsIs = modeDict[currentMode],
      newMode = 'cash'

    if (modeOkAsIs) return currentMode

    if (hasLoan) newMode = 'loan'
    if (hasLease) newMode = 'lease'

    return newMode
  },
  showPaymentRange = state => {
    let { paymentRangePercent, paymentRoundTo } = getPaymentMods(state)

    return (
      !getIsKnownCustomerFromState(state) &&
      (paymentRangePercent > 0 || paymentRoundTo > 0)
    )
  },
  showPersonalizeMessage = state => {
    return !getIsKnownCustomerFromState(state)
  },
  doesQuoteHaveTaxes = (state, vId, qId) => {
    return getQuoteValue(state, vId, qId, 'programsHaveTaxes', false)
  },
  doesQuoteHaveFees = (state, vId, qId) => {
    return getQuoteValue(state, vId, qId, 'programsHaveFees', false)
  },
  shouldQuoteHaveTaxes = (state, vId, qId) => {
    return (
      doesQuoteHaveTaxes(state, vId, qId) || getShouldIncludeTaxesSetting(state)
    )
  },
  shouldQuoteHaveFees = (state, vId, qId) => {
    return (
      doesQuoteHaveFees(state, vId, qId) || getShouldIncludeFeesSetting(state)
    )
  },
  shouldShowPrice = state => {
    return (
      getIsLoggedIn(state) ||
      getIsKnownCustomerFromState(state) ||
      !getHidePriceToUnknownSetting(state)
    )
  },
  shouldShowSummaryInline = (state, vId, qId, mode) => {
    //const canShow =
    //    !showPaymentRange(state) &&
    //    getQuoteValue(state, vId, qId, 'programsHaveTaxes') &&
    //    getQuoteValue(state, vId, qId, 'programsHaveFees'),
    //  ftcComplianceFlag = getHasUseFtcCompliantDisplay(state)
    //return ftcComplianceFlag || (canShow && mode === 'cash')
    return true
  },
  shouldShowSummaryLink = (state, vId, qId) => {
    const canShow =
        !showPaymentRange(state) &&
        getQuoteValue(state, vId, qId, 'programsHaveTaxes') &&
        getQuoteValue(state, vId, qId, 'programsHaveFees'),
      ftcComplianceFlag = getHasUseFtcCompliantDisplay(state)
    return ftcComplianceFlag || canShow
  },
  doesPrepencilExist = (state, vIq, qId) =>
    !!getQuoteValue(state, vIq, qId, 'price', 0),
  getProgramCount = (state, vId, qId) => {
    let ids = getQuoteIds(state, vId, qId),
      programs = getQuoteValue(state, ids.vehicle, ids.quote, 'programs', {})

    return Object.keys(programs).length || 0
  }
